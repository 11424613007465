'use client';

import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';
import ErrorBoundaryFallback from '@lobox/uikit/ErrorBoundary/ErrorBoundary.fallback';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  const reportSentry = async (error: Error) => {
    console.log('🚀🚀🚀 ~ reportSentry ~ error', error);
    await Sentry.captureException(error);
  };

  useEffect(() => {
    // Log the error to an error reporting service
    console.error('TOP LEVEL ERROR', error);
    reportSentry(error);
  }, [error]);

  return <ErrorBoundaryFallback error={error} reset={reset} />;
}
